import { Component, ViewChild, OnInit } from '@angular/core';
import { DashboardChartsService } from './state/dashboard.service';
import { DashboardChartsQuery } from './state/dashboard.query';
import 
  { ApexAxisChartSeries, ChartComponent, ApexNonAxisChartSeries, 
    ApexResponsive, ApexChart, ApexDataLabels, ApexPlotOptions, ApexXAxis, 
    ApexYAxis, ApexStroke, ApexLegend, 
    ApexFill,
    ApexTooltip
  } from "ng-apexcharts";
import { FormBuilder, FormGroup } from '@angular/forms';

export type PieChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  stroke: ApexStroke;
  title: any;
  colors: any;
  legend: ApexLegend;
  tooltip: ApexTooltip;
};

export type BarChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  responsive: ApexResponsive[];
  colors: any;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild("chart") chart!: ChartComponent;
  public paidCertsOptions!: Partial<PieChartOptions>;
  public orgSubscriptionsRevenue!: Partial<BarChartOptions>;
  public courseRegCertOptions!: Partial<BarChartOptions>;
  public courseFreeCertOptions!: Partial<BarChartOptions>;
  public OrganizationRevenueMonthly!: Partial<BarChartOptions>;
  public TotalRevenueMonthly!: Partial<BarChartOptions>;
  
  filtersForm!: FormGroup;
  displayCharts = false;

  dailyTotalPurchases: any;
  dailyTotalCertificates: any;
  dailyTotalPayAsYouGo: any;
  dailyTotalSubscriptions: any;
  dailyTotalRenewal: any;

  trendTotalPurchases: any;
  trendTotalCertificates: any;
  trendTotalPayAsYouGo: any;
  trendTotalSubscriptions: any;
  trendTotalRenewal: any;

  totalCertificates: any;
  totalSubsciptionsByPlan: number = 0;
  totalPaidRegistration: any;
  totalPaidCertificates: any;
  totalFreeRegistration: any;
  totalFreeCertificates: any;



  paidCertsTotal: any;
  orgSubscriptionsTotal: any;

  chartWidth = 1200;

  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardChartsService,
    private dashboardQuery: DashboardChartsQuery
  ) { 
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()-1);
    const startDateTrend = new Date(2023, 8, 1);

    this.filtersForm = this.fb.group({
      fromDateDaily: this.fb.control(startDate || null),
      toDateDaily: this.fb.control(startDate || null),
      fromDateTrend: this.fb.control(startDateTrend || null),
      toDateTrend: this.fb.control(startDate || null),
    });
    this.getDashboardData();
  }

  ngOnInit(): void {
    this.filtersForm.valueChanges.subscribe(() => {
      this.getDashboardData();
    });


    this.dashboardQuery.selectAll().subscribe((data) => {
      if (data.length === 0) return;
      const dashboard = data[0];
      var colors = ["#000033", "#29B494", "#C1C1C1", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"];

      this.dailyTotalPurchases = dashboard.dailyTotalPurchases;
      this.dailyTotalCertificates = dashboard.dailyTotalCertificates;
      this.dailyTotalPayAsYouGo = dashboard.dailyTotalPayAsYouGo;
      this.dailyTotalSubscriptions = dashboard.dailyTotalSubscriptions;
      this.dailyTotalRenewal = dashboard.dailyTotalRenewal;

      this.trendTotalPurchases = dashboard.trendTotalPurchases;
      this.trendTotalCertificates = dashboard.trendTotalCertificates;
      this.trendTotalPayAsYouGo = dashboard.trendTotalPayAsYouGo;
      this.trendTotalSubscriptions = dashboard.trendTotalSubscriptions;
      this.trendTotalRenewal = dashboard.trendTotalRenewal;

      // Chart 1 Data Paid Certificates by Course
      const firstChart = dashboard.adminDashboardCharts![0];
      var chartData1: any[] = [];
      var chartLabels1: any[] = [];
      this.totalCertificates = firstChart.totalCount1Tally;
      firstChart.chart?.forEach((chart: any) => {
        chartData1.push(chart.count1.total);
        chartLabels1.push(chart.description1.description);
      });
      if (chartLabels1.length % (colors.length + 1) === 0) {
        colors.pop();
      }
      this.paidCertsOptions = {
        series: chartData1,
        chart: {
          width: this.chartWidth,
          type: "pie"
        },
        labels: chartLabels1,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 450
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ],
        stroke: {
          show: true,
          width: 0
        },
        colors: colors,
        title: {
          text: "Paid Certificates by Course"
        },
        legend: {
          offsetY: -20,
          formatter: function (val: any, opts: any) {
            const label = opts.w.globals.labels[opts.seriesIndex];
            const value = opts.w.globals.series[opts.seriesIndex].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return `<div style="display: flex; justify-content: space-between;">
                      <div style="text-align: left;">${label}</div>
                      <div style="text-align: right; padding-left:40px;">${value}</div>
                    </div>`;
          }
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          }
        }
      };

      // Chart 2 Subscriptions Revenue
      const secondChart = dashboard.adminDashboardCharts![1];
      var chartMoneyData2: any[] = [];
      var chartCountData2: any[] = [];
      var chartLabels2: any[] = [];
      var chartTick2: any = 0;

      secondChart.chart?.forEach((chart: any) => {
        this.totalSubsciptionsByPlan = this.totalSubsciptionsByPlan + chart.count1.total;

        // Chart 2 Subscriptions Revenue Data
        chartMoneyData2.push(chart.money1.moneyTotal);
        chartCountData2.push(chart.count1.total);

        chartTick2 = chart.count1.total > chartTick2 ? chart.count1.total : chartTick2;
        chartLabels2.push(`${this.getFirstWord(chart.description1.description)} ${chart.description2.description}`);
      });
      chartTick2 = chartTick2 < 10 ? chartTick2 : undefined;

      // Chart 2 Subscriptions Revenue
      this.orgSubscriptionsRevenue = {
        series: [{
          name: "Subscription Revenue",
          data: chartMoneyData2
        
        }],
        dataLabels: {
          formatter: (val) => {
            return this.formatToDollarAmount(+val);
          }
        },
        chart: {
          type: "bar",
          height: 750,
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          categories: chartLabels2,
          labels: {
            formatter: (val) => {
              return this.formatToDollarAmount(+val);
            }
          }
        },
        yaxis: {
          floating: true,
          labels: {
            minWidth: 450,
            maxWidth: 900,
            style: {
              colors: "#000033"
            }
          } 
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return this.formatToDollarAmount(+val);
            }
          }
        },
        colors: ["#000033"],
      };


      // Chart 3 Data Course Registrations and Certificates
      const thirdChart = dashboard.adminDashboardCharts![2];
      var chartColumn1Data3: any[] = [];
      var chartColumn1Labels3: any[] = [];
      // var chartColumn2Data3: any[] = [];
      var chartColumn3Data3: any[] = [];
      this.totalPaidRegistration = thirdChart.totalCount1Tally
      // this.totalPaidCertificates = thirdChart.totalCount2Tally
      this.totalPaidCertificates = thirdChart.totalCount3Tally
      thirdChart.chart?.forEach((chart: any) => {
        chartColumn1Data3.push(chart.count1.total);
        chartColumn1Labels3.push(chart.description1.description);
        // chartColumn2Data3.push(chart.count2.total);
        chartColumn3Data3.push(chart.count3.total);
      });
      this.courseRegCertOptions = {
        series: [
          {
            name: "Registrations",
            data: chartColumn1Data3
          },
          // {
          //   name: "Certificates",
          //   data: chartColumn2Data3
          // },
          {
            name: "Certificates",
            data: chartColumn3Data3
          }
        ],
        chart: {
          type: "bar",
          height: 750,
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false,
          formatter: (val) => {
            return this.formatNumber(+val);
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: chartColumn1Labels3,
          min: undefined,
          max: undefined,
          labels: {
            maxHeight: 120,
            formatter: (val) => {
              return this.formatNumber(+val);
            }
          } 
        },
        yaxis: {
          floating: true,
          labels: {
            minWidth: 450,
            maxWidth: 900,
            style: {
              colors: "#000033"
            }
          } 
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return this.formatNumber(+val);
            }
          }
        },
        colors: ["#000033", "#29B494", "#C1C1C1", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"],
      };
      this.displayCharts = true;


      // Chart 4 Data Course Registrations and Certificates
      const fourthChart = dashboard.adminDashboardCharts![3];
      var chartColumn1Data4: any[] = [];
      var chartColumn1Labels4: any[] = [];
      var chartColumn2Data4: any[] = [];
      this.totalFreeRegistration = fourthChart.totalCount1Tally
      this.totalFreeCertificates = fourthChart.totalCount2Tally
      fourthChart.chart?.forEach((chart: any) => {
        chartColumn1Data4.push(chart.count1.total);
        chartColumn1Labels4.push(chart.description1.description);
        chartColumn2Data4.push(chart.count2.total);
      });

      this.courseFreeCertOptions = {
        series: [
          {
            name: "Registrations",
            data: chartColumn1Data4
          },
          {
            name: "Certificates",
            data: chartColumn2Data4
          }
        ],
        chart: {
          type: "bar",
          height: 750,
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false,
          formatter: (val) => {
            return this.formatNumber(+val);
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: chartColumn1Labels4,
          min: undefined,
          max: undefined,
          labels: {
            maxHeight: 120,
            formatter: (val) => {
              return this.formatNumber(+val);
            }
          } 
        },
        yaxis: {
          floating: true,
          labels: {
            minWidth: 450,
            maxWidth: 900,
            style: {
              colors: "#000033"
            }
          } 
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return this.formatNumber(+val);
            }
          }
        },
        colors: ["#000033", "#29B494", "#C1C1C1", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"],
      };
      this.displayCharts = true;

    // Chart 5 Organization Revenue
    const fifthChart = dashboard.adminDashboardCharts![4];
    var displayMonth5: any[] = [];
    var monthlySubscriptions: any[] = [];
    var monthlyRenewals: any[] = [];
    var monthlyPAYG: any[] = [];
    var monthlyEnterprise: any[] = [];
    var monthlyScorm: any[] = [];

    fifthChart.chart?.forEach((chart: any) => {
      displayMonth5.push(chart.description2.name);
      monthlySubscriptions.push(chart.money1.moneyTotal);
      monthlyRenewals.push(chart.money2.moneyTotal);
      monthlyPAYG.push(chart.money3.moneyTotal);
      monthlyEnterprise.push(chart.money4.moneyTotal);
      monthlyScorm.push(chart.money5.moneyTotal);
    });
    
    this.OrganizationRevenueMonthly = {
      series: [
        {
          name: "Subscriptions",
          data: monthlySubscriptions
        },
        {
          name: "Renewals",
          data: monthlyRenewals
        },
        {
          name: "Pay-As-You-Go",
          data: monthlyPAYG
        },
        {
          name: "Enterprise",
          data: monthlyEnterprise
        },
        {
          name: "SCORM",
          data: monthlyScorm
        }
      ],
      chart: {
        type: "bar",
        height: 700,
        stacked: true,
        toolbar: {
          show: true
        },
      },
      dataLabels: {
        formatter: (val) => {
          return this.formatToDollarAmount(+val);
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return this.formatToDollarAmount(+val);
          }
        }
      },
      xaxis: {
        type: "category",
        categories: displayMonth5
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return this.formatToDollarAmount(+val);
          }
        }
      },
      colors: ["#000033", "#29B494", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"],
    };

    // Chart 6 Total Revenue
    const sixthChart = dashboard.adminDashboardCharts![5];
    var displayMonth6: any[] = [];
    var monthlyOrgRevenue: any[] = [];
    var monthlyIndCertRevenue: any[] = [];

    sixthChart.chart?.forEach((chart: any) => {
      displayMonth6.push(chart.description2.name);
      monthlyIndCertRevenue.push(chart.money1.moneyTotal);
      monthlyOrgRevenue.push(chart.money2.moneyTotal);
    });

    this.TotalRevenueMonthly = {
      series: [
        {
          name: "Ind Certs",
          data: monthlyIndCertRevenue
        },
        {
          name: "Org Rev",
          data: monthlyOrgRevenue
        },
      ],
      chart: {
        type: "bar",
        height: 700,
        stacked: true,
        toolbar: {
          show: true
        },
      },
      dataLabels: {
        formatter: (val) => {
          return this.formatToDollarAmount(+val);
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return this.formatToDollarAmount(+val);
          }
        }
      },
      xaxis: {
        type: "category",
        categories: displayMonth6
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return this.formatToDollarAmount(+val);
          }
        }
      },
      colors: ["#000033", "#29B494", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"],
    };
    });


  }

  getDashboardData() {
    const { fromDateDaily, toDateDaily, fromDateTrend, toDateTrend } = this.filtersForm.value;
    const filter = {
      fromDateDaily: this.getDateString(fromDateDaily),
      toDateDaily: this.getDateString(toDateDaily),
      fromDateTrend: this.getDateString(fromDateTrend),
      toDateTrend: this.getDateString(toDateTrend),
    };
    
    this.dashboardService.all(filter).subscribe();
  }

  getDateString(date: any) {
    if (date) {
      const newDate = new Date(date);
      return newDate.toUTCString();
    }
  }

  formatNumber(value: number): string {
    if (value == null) {
      return '';
    }
    const roundedValue = Math.round(value);
    return `${roundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }

  formatToDollarAmount(value: number): string {
    return `$${this.formatNumber(value)}`;
  }

  getFirstWord(input: string): string {
    if (!input || input.trim().length === 0) {
      return '';
    }
    const words = input.trim().split(' ');
    
    if (words[1] === 'Business') {
      return words[0];
    }
    return input;

  }
}
