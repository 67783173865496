export const environment = {
  production: false,
  context: 'dev',
  apiURL: 'https://mrtapi-dev.azurewebsites.net',
  // apiURL: 'https://localhost:5001',
  domainName: 'https://mandatedreportertrainingportal-dev.azurewebsites.net',
  versionNumber: '1.6.0|10/09/2024',
  versionPollTime: 10, // in minutes
  recaptchaSiteKey: '6LdUltwpAAAAAG7ji4a7JFk-6OfqghSt3fM00mDm'
};
